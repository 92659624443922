/**
 * Created by andyosullivan on 20/06/2021.
 */
import React, { Component } from "react";
import "../Home/Home.css";
import "../About/About.css";
import "./Movies.css";
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import {withRouter} from 'react-router-dom';
import { movie_details } from '../../assets/movies/movie_details';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import twitter from '../../assets/images/twitter.png';
import {FacebookIcon, FacebookShareButton} from "react-share";

class Movies extends Component {
    constructor(props) {
        super(props);
        this.state = {
            movie: {
                "name": "White Men Can't Jump Review",
                "movie": "white-men-cant-jump-review",
                "subtitle": "A lot of fun",
                "rating": 5,
                "image_1": {image:"WMCJ-15190.jpg", text:"",alt:""},
                "image_2": {image:"dune001.jpg", text: ""},
                "image_3": "",
                "image_4": "",
                "image_5": "",
                "image_6": "",
                "paragraph_1": "Dune is an epic sci-fi movie based on a famous 1965 book",
                "paragraph_2": () => (
                    <span>Test</span>),
                "paragraph_3": "",
                "paragraph_4": "",
                "paragraph_5": "",
                "paragraph_6": "",
                "diversity": {},
                "date": "13th November '21",
                "allMovies": true,
                "image_sources":"",
                "reviewer":{name:"Test",twitter:"test twitter"},
            },
            movies: [],
        };
        this.filterArray = this.filterArray.bind(this);
    }

    componentDidMount () {
        this.deleteAllCookies();

        const movie = this.props.match.params.movie;
        if (movie === "all") {
            this.setState({"allMovies":true});
        } else {
            let result = (movie_details.filter(function (o) {
                return o.movie === movie;
            }));
            this.setState({movie: result[0]});
            this.setState({"allMovies":false});
            this.setState({url: "https://twitter.com/intent/tweet?text=Check%20out%20this%20post%20on%20https://NOadsHERE.com/movies/"});
        }
        this.setState({movies: movie_details});
    }

    deleteAllCookies() {
        var cookies = document.cookie.split(";");

        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i];
            var eqPos = cookie.indexOf("=");
            var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
        }

        localStorage.clear();
        sessionStorage.clear();
    }

    filterArray(e){
        var searched = movie_details.filter(({movie}) => movie.includes(e.target.value.toLowerCase()));
        this.setState({movies: searched});
    }


    render() {
        let temp = false;
        let temp2 = false;
        let temp3 = false;
        try {
            if (require("../../assets/images/" + this.state.movie.image_2.image)) {
                temp = true;
            }
        }catch (error){
            temp = false;
        }
        try {
            if (require("../../assets/images/" + this.state.movie.image_3.image)) {
                temp2 = true;
            }
        }catch (error){
            temp2 = false;
        }
        try {
            if (require("../../assets/images/" + this.state.movie.image_4.image)) {
                temp3 = true;
            }
        }catch (error){
            temp3 = false;
        }
        return (

            <div className="divmor">
                <Container>

                    <Row className="movieRow">
                        {!this.state.allMovies &&
                            <div>
                        <Col className="" sm={12} lg={8}>
                            <div className="movieDiv">
                                <h1 className="darkGrey">{this.state.movie.name}<br/>
                                    {this.state.movie.subtitle && <p className="subTitle"> {this.state.movie.subtitle}</p>}
                                    <span className="movieDate">{this.state.movie.date} - {this.state.movie.reviewer.twitter && <a href={this.state.movie.reviewer.twitter} rel="noopener noreferrer" target="_blank">{this.state.movie.reviewer.name}</a>}{!this.state.movie.reviewer.twitter && this.state.movie.reviewer.name}</span></h1>
                                <img className="movieImage"
                                     src={require("../../assets/images/" + this.state.movie.image_1.image).default}
                                     alt={this.state.movie.image_1.text}/>
                                <figcaption className="fig"><h4>{this.state.movie.image_1.text}</h4></figcaption>
                                <br/>
                                <p className="movieBlurb"> {this.state.movie.paragraph_1}
                                    <br/>
                                </p>
                                <p className="movieBlurb"><strong>Director:</strong> {this.state.movie.director}
                                </p>
                                <p className="movieBlurb"><strong>Main cast:</strong> {this.state.movie.main_cast}
                                    <br/>
                                </p>
                                <p className="movieBlurb">{this.state.movie.paragraph_2()}</p>
                                <p className="movieBlurb"> {this.state.movie.paragraph_3}</p>
                                {temp &&
                                    <div>
                                        <br/>
                                        <img className="movieImage"
                                             src={require("../../assets/images/" + this.state.movie.image_2.image).default}
                                             alt={this.state.movie.image_2.text}/>
                                        <figcaption className="fig"><h4>{this.state.movie.image_2.text}</h4></figcaption>
                                        <br/>
                                    </div>
                                }
                                {this.state.movie.paragraph_4 && <p className="movieBlurb"> {this.state.movie.paragraph_4}</p>}
                                {this.state.movie.paragraph_5 && <p className="movieBlurb"> {this.state.movie.paragraph_5}</p>}
                                {temp2 &&
                                    <div>
                                        <br/>
                                        <img className="movieImage"
                                             src={require("../../assets/images/" + this.state.movie.image_3.image).default}
                                             alt={this.state.movie.image_3.text}/>
                                        <figcaption className="fig"><h4>{this.state.movie.image_3.text}</h4></figcaption>
                                        <br/>
                                    </div>
                                }
                                {this.state.movie.paragraph_6 && <p className="movieBlurb"> {this.state.movie.paragraph_6}</p>}
                                {temp3 &&
                                    <div>
                                        <br/>
                                        <img className="movieImage"
                                             src={require("../../assets/images/" + this.state.movie.image_4.image).default}
                                             alt={this.state.movie.image_4.text}/>
                                        <figcaption className="fig"><h4>{this.state.movie.image_4.text}</h4></figcaption>
                                        <br/>
                                    </div>
                                }
                                {this.state.movie.paragraph_7 && <p className="movieBlurb"> {this.state.movie.paragraph_7}</p>}
                                <p className="movieBlurb"><strong>Summary:</strong> {this.state.movie.summary}</p>
                                <p className="movieBlurb"><strong>Rating:</strong> {this.state.movie.rating} stars</p>
                                <p className="movieBlurb endBit sources">All images courtesy of: <a href={this.state.movie.image_sources} rel="noopener noreferrer" target="_blank">{this.state.movie.image_sources}</a> </p>

                            </div>
                        </Col>


                        <Col className="diversityCol" sm={12} lg={4}>

                            <div className="diversityDiv">
                                <p className="leftText movieBlurb"><strong>Diverse or not diverse?</strong></p>

                                {Array.from(this.state.movie.diversity).map((item) => (
                                    <Card className={item.className} key={item.question}>
                                        <p className="movieBlurb"><strong>{item.question}</strong></p>
                                        <p className="movieBlurb">{item.answer} </p>
                                    </Card>
                                    )
                                )}

                            </div>
                            <h3 className="shareText"><strong>Share this post on:</strong></h3>
                            <a className="twitter-share-button" rel="noopener noreferrer" target="_blank"
                               href={this.state.url + this.state.movie.movie}>
                                <img className="social floatLeft" src={twitter} alt="Twitter logo"/></a>
                            <br/>
                            <br/>
                            <FacebookShareButton
                                className="facebookButton"
                                url={'https://www.noadshere.com/movies/' + this.state.movie.movie}
                                quote={'Check out this review!'}
                                hashtag="#NOadsHERE"
                            >
                                <FacebookIcon size={32} round />
                            </FacebookShareButton>
                            <br/>
                            <br/>

                        </Col>
                            </div>
                        }
                        {this.state.allMovies &&
                            <div>

                                <Col className="" sm={12} lg={12}>
                                    <div className="movieDiv">
                                        <Row>
                                            <Col className="" sm={4} lg={8}>
                                                <h1 className="darkGrey">Movie Reviews</h1>
                                                <h4>We couldn't build a site like this without movie reviews. Some are new releases, some are
                                                movies we come across on the streamers. For all movies we give an honest opinion
                                                and no spoilers! We also have a 'Diverse or not Diverse' section for each movie!</h4>
                                                <br/>
                                            </Col>
                                        </Row>
                                        <Row>
                                        <Col className="" sm={4} lg={4}>
                                            <Form.Group>
                                                <Form.Control className="" type="input" id="searchId" placeHolder="Search movies" onChange={this.filterArray}/>
                                            </Form.Group>
                                        </Col>
                                        </Row>
                                        <Row className="allRow">
                                            {Array.from( this.state.movies).map((item) => (
                                                    <Col className="tvCol" sm={4} lg={4}>
                                                        <div className="bgImageDiv" style={{backgroundImage: "url(" + require("../../assets/images/" + item.image_1.image).default,  backgroundSize: "cover"}}>
                                                            <Card className="contentCard tv" key={item.name}>
                                                                <a className="cardLink" href={"/movies/" + item.movie} >
                                                                    <p className="cardLinkText">{item.name}</p></a>
                                                            </Card>
                                                        </div>
                                                    </Col>
                                                )
                                            )}
                                        </Row>

                                    </div>
                                </Col>
                            </div>
                        }
                    </Row>
                </Container>
                <div className="footerAbout">
                    <a className="footerLinks" href="/">home</a>
                    <a className="footerLinks" href="/tv/all">tv</a>
                    <a className="footerLinks" href="/movies/all">movies</a>
                    <a className="footerLinks" href="/recipes/all">recipes</a>
                    <a className="footerLinks" href="/about/">about</a>
                </div>
            </div>
        );
    }
}

export default withRouter(Movies);

